import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessCardComponent } from './business-card/business-card.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { IonicRatingModule } from 'ionic-rating';
import { RatingModule } from 'ng-starrating';
import { MyCartComponent } from './my-cart/my-cart.component';
import { IonicModule } from '@ionic/angular';

// import { StarRatingModule } from 'ionic3-star-rating';
@NgModule({
  declarations: [BusinessCardComponent, MyCartComponent],
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,
    IonicRatingModule,
    RatingModule,
    IonicModule,
    // StarRatingModule,
  ],
  exports: [BusinessCardComponent, MyCartComponent],
})
export class ComponentsModule {}
