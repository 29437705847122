import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { ComponentsModule } from './components/components.module';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServerService } from './providers/server.service';
import { GlobalVariable } from './global';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
// import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { HttpClientModule } from '@angular/common/http';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
// import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
// import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
// import { StarRatingModule } from 'ionic3-star-rating';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { Stripe } from '@awesome-cordova-plugins/stripe/ngx';
// import { IonicRatingModule } from 'ionic-rating';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { ImagePicker } from '@awesome-cordova-plugins/image-picker/ngx';
import { ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'ion2-calendar';
import { RatingModule } from 'ng-starrating';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { BranchIo } from '@awesome-cordova-plugins/branch-io/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ComponentsModule,
    HttpClientModule,
    RatingModule,
    ReactiveFormsModule,
    CalendarModule.forRoot({
      doneLabel: 'Save',
      closeIcon: true,
    }),
    // IonicRatingModule,
    // NgxSkeletonLoaderModule,
    // StarRatingModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },

    SocialSharing,
    ServerService,
    GlobalVariable,
    InAppBrowser,
    LocationAccuracy,
    AndroidPermissions,
    NavParams,
    BranchIo,
    Camera,
    PhotoViewer,
    GooglePlus,
    ImagePicker,
    NativeStorage,
    // Facebook,
    Network,
    SplashScreen,
    NativeGeocoder,
    // OneSignal,
    LaunchNavigator,
    StatusBar,
    Geolocation,
    Stripe,
    CallNumber,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
