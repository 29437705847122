import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { GlobalVariable } from '../global';
@Injectable({
  providedIn: 'root',
})
export class ServerService {
  // httpOptions: { headers: HttpHeaders };
  // httpOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Access-Control-Allow-Origin': '*',
  //   }),
  // };
  categoryId: any;
  timeZone: any;
  isBirthdayCount: any = 0;
  reward_count: any = 0;
  punchcount: any = 0;
  O: Observable<any>;
  constructor(private http: HttpClient, private global: GlobalVariable) {}
  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  LoginData(LoginData): Observable<any> {
    var link = this.global.BaseUrl + 'Customer_controller/login';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      email: LoginData.email.trim(),
      phone: LoginData.code + LoginData.phone,
      password: LoginData.password,
    });

    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }

  getPlace(id): Observable<any> {
    var link = this.global.BaseUrl + 'Customer_controller/getplaces';
    var data;
    data = JSON.stringify({
      user_timezone_set: this.timeZone,
      business_id: id,
      coordinates: this.global.mycoordinates,
      // business_type:'ordering',
      limit: '1',
    });
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  GetBusinessMenuCategories(BusinessId): Observable<any> {
    if (
      this.global.business_type == 'retail' ||
      this.global.business_type == 'salon'
    ) {
      var link = this.global.BaseUrl + 'retail/categories_customers';
      // var link = (this.global.BaseUrl + 'retail/categories');
    } else {
      var link = this.global.BaseUrl + 'menu/categories';
    }
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      business_id: BusinessId,
      platform: 'app',
    });
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  checkPromotional(id,quantity): Observable<any> {
    var link = this.global.BaseUrl + 'menu/checkPromotionalItem';

    var data = JSON.stringify({
      item_id: id,
      quantity:quantity,
      udid: this.global.udid,
    });
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  GetSuperCategories(BusinessId, super_id): Observable<any> {
    if (
      this.global.business_type == 'retail' ||
      this.global.business_type == 'salon'
    ) {
      var link = this.global.BaseUrl + 'retail/categories';
    } else {
      var link = this.global.BaseUrl + 'menu/categories';
    }
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      business_id: BusinessId,
      platform: 'app',
      super_category_id: super_id,
    });
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  date_convert(date): Observable<any> {
    var link = this.global.BaseUrl + '/menu/get_date_convert';
    var data = JSON.stringify({ user_timezone_set: this.timeZone, date: date });
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  appVersion(platform) {
    var link = this.global.BaseUrl + 'customer_controller/checkAppVersion';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      app_version: this.global.app_version,
      platform: platform,
    });
    console.log('version check data to api',data)
    return this.http.post<any>(link, data);
    // .pipe(retry(0), catchError(this.handleError));
  }
  getNotificationData(appId, userId, loginId): Observable<any> {
    let link = this.global.BaseUrl + 'Notifications/SaveUserConfig';
    let data;
    data = JSON.stringify({
      user_timezone_set: this.timeZone,
      appId: appId,
      userId: userId,
      loginId: loginId,
    });
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  SignupData(
    socialLogin,
    firstname,
    lastname,
    email,
    password,
    phone,
    Birthday,
    Anniversary,
    profile_complete,
    appleToken?
  ): Observable<any> {
    var link = this.global.BaseUrl + 'Customer_controller/signup';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      appleToken: appleToken,
      socialLogin: socialLogin,
      profile_complete: profile_complete,
      business_id: this.global.new_id,
      firstname: firstname,
      lastname: lastname,
      email: email,
      password: password,
      phone: phone,
      birthday: Birthday,
      anniversary: Anniversary,
    });
    // alert('data to api =>        '+data)
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public getRestaurantslist(
    radius,
    businesType,
    coordinates,
    offset,
    type,
    limit
  ): Observable<any> {
    let link = this.global.BaseUrl + 'Customer_controller/getplaces';
    this.global.main_id = '76';
    let bID = this.global.main_id;
    let data;
    if (this.global.marketPlace) {
      //  data = JSON.stringify({user_timezone_set:this.timeZone,coordinates: coordinates,business_type: 'marketPlace', radius: radius, offset });
      bID = '';
    }
    // coordinates = '40.760936, -73.515513'
    // else{
    data = JSON.stringify({
      user_timezone_set: this.timeZone,
      // business_id: bID,
      coordinates: this.global.mycoordinates,
      radius: radius,
      business_type: 'marketPlace',
      offset: offset,
      type: type,
      udid: this.global.udid,
      limit: limit,
      get_menu: true,
    });
    console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  getCuisineSearchedList(keyword): Observable<any> {
    // http://192.168.0.112/halalguideserver/index.php/Admin_controller/DashBoardApi
    // let link = this.global.BaseUrl + 'Customer_controller/getplaces';
    let link = this.global.BaseUrl + 'Customer_controller/SearchPlaces';

    this.global.main_id = '76';
    let bID = this.global.main_id;
    let data;
    if (this.global.marketPlace) {
      //  data = JSON.stringify({user_timezone_set:this.timeZone,coordinates: coordinates,business_type: 'marketPlace', radius: radius, offset });
      bID = '';
    }
    // coordinates = '40.760936, -73.515513'
    // else{
    data = JSON.stringify({
      user_timezone_set: this.timeZone,
      // business_id: bID,
      coordinates: this.global.mycoordinates,
      radius: '10000',
      business_type: this.global.marketPlace ? 'marketPlace' : 'marketPlace',
      offset: 0,
      // type: type,
      // udid: this.global.udid,
      limit: 20,
      get_menu: true,
      keyword: keyword,
    });
    console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  getAddress(coordinates, branchId = false): Observable<any> {
    var link =
      this.global.BaseUrl + 'Customer_controller/get_address_with_coordinates';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      business_id: this.global.bussinessId,
      coordinates: coordinates,
    });
    console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }

  BusinessInformation(bId, username, udid): Observable<any> {
    var link = this.global.BaseUrl + 'Customer_controller/ShowUserReviews';
    // var link = this.global.BaseUrl + 'Customer_controller/getbusinessinfo';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      business_id: bId,
      business_username: bId,
      udid: udid,
    });
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  getCuisinesList(
    radius,
    businesType,
    coordinates,
    offset,
    type,
    limit,
    cuisineID
  ): Observable<any> {
    // let link = this.global.BaseUrl + 'Customer_controller/getplaces';
    let link = this.global.BaseUrl + 'Customer_controller/GetFilterdPlaces';

    this.global.main_id = '76';
    let bID = this.global.main_id;
    let data;
    if (this.global.marketPlace) {
      //  data = JSON.stringify({user_timezone_set:this.timeZone,coordinates: coordinates,business_type: 'marketPlace', radius: radius, offset });
      bID = '';
    }
    // coordinates = '40.760936, -73.515513'
    // else{
    data = JSON.stringify({
      user_timezone_set: this.timeZone,
      business_id: bID,
      coordinates: this.global.mycoordinates,
      radius: radius,
      business_type: this.global.marketPlace ? 'marketPlace' : 'marketPlace',
      offset: offset,
      type: type,
      udid: this.global.udid,
      limit: limit,
      get_menu: true,
      cuisine_id: cuisineID,
    });
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  LoadBannersOnHomePage(): Observable<any> {
    var link = this.global.BaseUrl + 'Customer_controller/get_banners';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      udid: this.global.udid,
      business_id: this.global.new_id,
      app_version: this.global.app_version,
    });

    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  getOfferBanners(): Observable<any> {
    let link = this.global.BaseUrl + 'Business_controller/GetBanners';
    return this.http.get<any>(link).pipe(catchError(this.handleError));
  }
  getCuisines(): Observable<any> {
    // var link = this.global.BaseUrl + 'Cuisines_controller/index_get';
    let link = this.global.BaseUrl + 'Cuisines_controller/GetCuisines';
    return this.http.get<any>(link).pipe(catchError(this.handleError));
  }

  getAllNotifications(): Observable<any> {
    // let link = this.global.BaseUrl + 'Notifications/';
    let link = this.global.BaseUrl + 'Notifications/FetchNotifications';
    let data;
    data = JSON.stringify({
      user_timezone_set: this.timeZone,
      udid: this.global.udid,
    });
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  verifyBusinessCheck(business_id): Observable<any> {
    var link = this.global.BaseUrl + 'listing_controller/sendVerificationCode';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      business_id: business_id,
    });
    console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  reportBusiness(business_id, udid): Observable<any> {
    var link = this.global.BaseUrl + 'Reviews_controller/AddClaim';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      business_id: business_id,
      udid: udid,
      description: 'Request for Permanently Closed',
    });
    console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  getCuisinesOfBusiness(business_id): Observable<any> {
    var link = this.global.BaseUrl + 'Cuisines_controller/Cuisines';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      business_id: business_id,
    });
    console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }

  ProductItemDetail(ItemId): Observable<any> {
    if (
      this.global.business_type == 'retail' ||
      this.global.business_type == 'salon'
    ) {
      var link = this.global.BaseUrl + 'retail/item_details';
    } else {
      var link = this.global.BaseUrl + 'menu/item_details';
    }

    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      item_id: ItemId,
    });
    console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }

  getUserLotteryRewards(coordinates): Observable<any> {
    var link = this.global.BaseUrl + 'Customer_controller/get_rewards';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      udid: this.global.udid,
      coordinates: coordinates,
    });
    console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  PaymentThroughPayOnVenue(instruction, amount): Observable<any> {
    var link = this.global.BaseUrl + 'menu/place_order_r';
    var orderdata = JSON.stringify({
      user_timezone_set: this.timeZone,
      udid: this.global.udid,
      payment_info: '',
      order_info: this.global.Product,
      instruction: instruction,
      total: amount,
      payment_type: 'Pay_on_venue',
      reservation_id: this.global.reservation_id,
    });
    console.log(orderdata);
    return this.http
      .post<any>(link, orderdata)
      .pipe(catchError(this.handleError));
  }
  OrderConfirmationThroughPaypalReservation(
    orderStatus,
    orderId
  ): Observable<any> {
    var link = this.global.BaseUrl + 'menu/status_order_pp_r';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      status: orderStatus,
      order_id: orderId,
    });
    console.log('oderstatusdata', data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }

  OrderConfirmationThroughPaypal(orderStatus, orderId): Observable<any> {
    var link = this.global.BaseUrl + 'menu/status_order_pp';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      status: orderStatus,
      order_id: orderId,
    });
    console.log('oderstatusdata', data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }

  OrderStatusThroughPaypalReservation(instruction, cashpay): Observable<any> {
    var link = this.global.BaseUrl + 'menu/place_order_pp_r';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      udid: this.global.udid,
      payment_info: '',
      order_info: this.global.Product,
      instruction: instruction,
      total: cashpay,
      payment_type: 'Paypal',
      reservation_id: this.global.reservation_id,
    });
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }

  OrderStatusThroughPaypal(
    Address,
    instruction,
    myDate,
    cashpay
  ): Observable<any> {
    var link = this.global.BaseUrl + 'menu/place_order_pp';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      udid: this.global.udid,
      payment_info: Address,
      order_info: this.global.Product,
      instruction: instruction,
      scheduled_time: myDate,
      total: cashpay,
      payment_type: 'Paypal',
    });
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  LotteryRedeeem(StorePoint, special_flag): Observable<any> {
    var link =
      this.global.BaseUrl + 'business_controller/app_lottery_redeem_orders';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      username: this.global.BusinessID,
      qr_code: this.global.udid,
      amount: StorePoint,
      is_special: special_flag,
    });
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }

  PaymentThroughStripeReservation(instruction, amount, Token): Observable<any> {
    var link = this.global.BaseUrl + 'menu/place_order_r';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      udid: this.global.udid,
      payment_info: { address: '', token: Token },
      order_info: this.global.Product,
      instruction: instruction,
      total: amount,
      payment_type: 'Stripe',
      reservation_id: this.global.reservation_id,
    });
    console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }

  PaymentThroughStripe(
    subTotal,
    Address,
    instruction,
    amount,
    order_date,
    Token,
    status,
    cash_discount,
    cardinfo?
  ): Observable<any> {
    let cartItems = this.global.Product;
    cartItems.forEach((orderItems) => {
      if (orderItems.menuExtrasSelected.length > 0) {
        orderItems.menuExtrasSelected.forEach((option) => {
          console.log(option);
          if (option.hasOwnProperty('options')) {
            console.log('has property', option.hasOwnProperty('options'));
            option.optionNameSelected = option.options;
          }
        });
      }
    });

    if (
      this.global.business_type == 'retail' ||
      this.global.business_type == 'salon'
    ) {
      var link = this.global.BaseUrl + 'retail/place_order';
    } else {
      var link = this.global.BaseUrl + 'menu/place_order';
    }
    console.log(orderdata, order_date);
    var orderdata = JSON.stringify({
      user_timezone_set: this.timeZone,
      udid: this.global.udid,
      payment_info: {
        cash_discount: cash_discount,
        address: Address,
        token: Token,
        cardInfo: cardinfo,
        admin_stripe_enabled: this.global.admin_stripe_enabled,
        authorize_enabled: this.global.authorize_enabled,
      },
      order_info: cartItems,
      instruction: instruction,
      total: amount,
      subtotal: subTotal, //added 1-20-2022
      scheduled_time: order_date,
      payment_type: status,
    });
    console.log('stripe', orderdata, order_date);
    return this.http
      .post<any>(link, orderdata)
      .pipe(catchError(this.handleError));
  }

  PaymentOnDelivery(
    subtotal,
    Address,
    instruction,
    amount,
    order_date,
    Token,
    status
  ): Observable<any> {
    console.log(subtotal);
    let cartItems = this.global.Product;
    cartItems.forEach((orderItems) => {
      if (orderItems.menuExtrasSelected.length > 0) {
        orderItems.menuExtrasSelected.forEach((option) => {
          console.log(option);
          if (option.hasOwnProperty('options')) {
            console.log('has property', option.hasOwnProperty('options'));
            option.optionNameSelected = option.options;
          }
        });
      }
    });

    // debug:
    if (
      this.global.business_type == 'retail' ||
      this.global.business_type == 'salon'
    ) {
      var link = this.global.BaseUrl + 'retail/place_order_cash';
    } else {
      var link = this.global.BaseUrl + 'menu/place_order';
      // var link ='http://192.168.18.91/halalguideserver/index.php/Menu/place_order_cash';
    }
    let business_id = '';
    if (this.global.selected_business_id) {
      business_id = this.global.selected_business_id;
    } else {
      business_id = this.global.new_id;
    }
    var orderdata = JSON.stringify({
      user_timezone_set: this.timeZone,
      udid: this.global.udid,
      payment_info: { address: Address, token: Token },
      order_info: cartItems,
      instruction: instruction,
      total: amount,
      subtotal: subtotal,
      scheduled_time: order_date,
      payment_type: status,
      business_id: business_id,
    });
    console.log('stripe', orderdata, order_date);
    return this.http
      .post<any>(link, orderdata)
      .pipe(catchError(this.handleError));
  }
  userBusinessAvailedDiscount(): Observable<any> {
    var link = this.global.BaseUrl + 'Customer_controller/customer_discount';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      udid: this.global.udid,
      b_id: this.global.bussinessId,
      discount_count: this.global.availed_discount_count,
    });
    console.log('oderstatusdata', data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  deleteUserAccount(): Observable<any> {
    var link =
      this.global.BaseUrl + 'Customer_controller/delete_customer_account';
    var data = JSON.stringify({
      udid: this.global.udid,
    });
    console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  RedeemUserPoints(): Observable<any> {
    var link = this.global.BaseUrl + 'Customer_controller/redeem_points';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      udid: this.global.udid,
      b_id: this.global.bussinessId,
      amount: this.global.points_availed,
    });
    console.log('redeem points', data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }

  AddUserReview(
    review_id,
    user_email,
    images,

    ReviewData,
    index1,
    bussiness
  ): Observable<any> {
    var link = this.global.BaseUrl + 'Customer_controller/review';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      useremail: user_email,
      review_id: review_id,
      images: images,
      title: '',
      description: ReviewData.description,
      rating: index1,
      business: bussiness,
      udid: this.global.udid,
    });
    console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  getPickupslots(id, time): Observable<any> {
    var link = this.global.BaseUrl + 'Customer_controller/get_pickup_slots';
    var data;
    data = JSON.stringify({
      user_timezone_set: this.timeZone,
      business_id: id,
      schedule_time: time,
    });
    console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  showUserReviews(user_email,udid): Observable<any> {
    var link = this.global.BaseUrl + 'Customer_controller/getCustomerReviews';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      email: user_email,
      udid: udid,
    });
    console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
           showUserBusiness(udid): Observable<any> {
    var link =
      this.global.BaseUrl +
      'Business_api_controller/customerDiscoveredBusinesses';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      // email: user_email,
      udid: udid,                        
        
    });
    console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }

  getLeaderBoardData(): Observable<any> {
    var link = this.global.BaseUrl + 'Reviews_controller/TopReviews';
    return this.http.get<any>(link).pipe(catchError(   this.handleError));
  }
  getBusinessLeaderBoardData(): Observable<any> {
    var link = this.global.BaseUrl + 'Business_api_controller/topLeaderBoard';
    return this.http.get<any>(link).pipe(catchError(this.handleError));
  }
  UpdateUserProfile(
    firstname,
    user_email,
    lastname,
    password,
    phone,
    udid
  ): Observable<any> {
    if (user_email == undefined) {
      user_email = '';
    }

    var link = this.global.BaseUrl + 'Customer_controller/update_profile';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      business_id: '',
      udid: udid,
      firstname: firstname,
      lastname: lastname,
      password: password,
      phone: phone,
      email: user_email,
    });
    console.log('data', data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  LiveSearch(ch, coordinates, radius, businesType): Observable<any> {
    var link = this.global.BaseUrl + 'Customer_controller/live_search';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      keyword: ch,
      coordinates: coordinates,
      radius: radius,
      business_type: businesType,
    });
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  GetUserOrderPlaced(): Observable<any> {
    if (
      this.global.business_type == 'retail' ||
      this.global.business_type == 'salon'
    ) {
      var link = this.global.BaseUrl + 'retail/order_history';
    } else {
      var link = this.global.BaseUrl + 'menu/order_history';
    }
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      udid: this.global.udid,
    });
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }

  my_gift_cards_design(): Observable<any> {
    var link = this.global.BaseUrl + 'giftcard/get_giftcard_designs';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      business_id: this.global.new_id,
    });
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  my_gift_cards(): Observable<any> {
    var link = this.global.BaseUrl + 'giftcard/get_customer_giftcards';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      business_id: this.global.new_id,
      udid: this.global.udid,
    });
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  addNewBusiness(
    name,
    address,
    email,
    phone,
    notes,
    lat,
    lng
  ): Observable<any> {
    var link =
      this.global.BaseUrl + 'Business_api_controller/newBusinessRequest';
    var data = JSON.stringify({
      user_timezone_set: this.timeZone,
      name: name,
      lat: lat,
      lng: lng,
      udid: this.global.udid,
      address: address,
      email: email,
      phone: phone,
      notes: notes,
    });
    console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  getBlogs(): Observable<any> {
    var link = this.global.BaseUrl + 'Admin_controller/getBlog';
    return this.http.get<any>(link).pipe(catchError(this.handleError));
  }
  getBlogDetails(id): Observable<any> {
    var link = this.global.BaseUrl + 'Admin_controller/getSingleBlog/'+id;
    return this.http.get<any>(link).pipe(catchError(this.handleError));
  }
  
  getAbout(): Observable<any> {
    var link = this.global.BaseUrl + 'Admin_controller/getAbout';
    return this.http.get<any>(link).pipe(catchError(this.handleError));
  }

  recentReviews(): Observable<any> {
    var link = this.global.BaseUrl + 'customer_controller/getRecentReviews';
    // var data = JSON.stringify({limit:limit });
    // console.log(data)
    return this.http.get<any>(link).pipe(catchError(this.handleError));
  }

  doorDash(
    business_address,
    delivery_address,
    order_amount,
    pickup_time,
    delivery_time,
    business_id,
    business_name,
    delivery_instr?
  ): Observable<any> {
    var link = this.global.BaseUrl + 'doordash/delivery_estimate';
    var data = JSON.stringify({
      business_address: business_address,
      delivery_address: delivery_address,
      order_amount: order_amount,
      pickup_time: pickup_time,
      delivery_time: delivery_time,
      business_id: business_id,
      business_name: business_name,
      delivery_instructions: delivery_instr,
    });
    console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  customerForgotPassword(email): Observable<any> {
    let path = this.global.BaseUrl + 'Customer_controller/forgot_password';
    let data = JSON.stringify({
      email: email,
    });
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  submitSurvey(category_id,nominee_id){
    let path = this.global.BaseUrl + 'Event_polling_controller/addNomineeVote';
    let data=JSON.stringify({
      udid:this.global.udid,
      category_id:category_id,
      nominee_id:nominee_id,

    })
    return this.http
      .post<any>(path,data)
      .pipe(retry(1), catchError(this.handleError));
    
  }
  getSurveyList(){
    let path = this.global.BaseUrl + 'Event_polling_controller/getPollingEvents';
    let data=JSON.stringify({
      "udid":this.global.udid,
    })
    return this.http
      .post<any>(path,data)
      .pipe(retry(1), catchError(this.handleError));

  }
  
}
