import { Component } from '@angular/core';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
import {
  AlertController,
  LoadingController,
  MenuController,
  ModalController,
  NavController,
  Platform,
} from '@ionic/angular';
import { GlobalVariable } from './global';
import { ServerService } from './providers/server.service';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
// import { OneSignal, OSNotificationPayload } from '@ionic-native/onesignal/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';

import { BranchIo } from '@awesome-cordova-plugins/branch-io/ngx';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  data: any;
  places: any;
  // sender_id = '1044840233950';//google sender id onesignal
  sender_id = '1017331347712'; //halalguide sender id google
  //  oneSignalAppId = '17221388-4fd8-4002-9a47-90e55ea73ce1';
  // oneSignalAppId = '17221388-4fd8-4002-9a47-90e55ea73ce1'; //onesignal app id
  oneSignalAppId = '5dbdfb40-6835-4866-acec-215f53fd5076'; //halalguide onesignal app id
  notificationsRecieved = [];
  timeIs: string;
  currentTimeSpan = Date.now();
  WEBPlatform: boolean = false;
  isLoggedIn: string;
  constructor(
    public platform: Platform,
    private network: Network,
    public loadingCtrl: LoadingController,
    private router:Router,
    // private iab: InAppBrowser,
    // private oneSignal: OneSignal,
    public alertCtrl: AlertController,
    public server: ServerService,
    private statusbar: StatusBar,
    public alertctrl: AlertController,
    public modalCtrl: ModalController,
    public globals: GlobalVariable,
    private splashscreen: SplashScreen,
    private nativeStorage: NativeStorage,
    private geolocation: Geolocation,
    public nav: NavController,
    public menuCtrl: MenuController,
    private branch: BranchIo,
  ) {
    this.initApp();
  }

  initApp() {
    this.platform.ready().then((isReady) => {
      this.splashscreen.hide();
      //   this.menuCtrl.isOpen().then(res=>{
      // //   this.isLoggedIn = localStorage.getItem("isLogin");

      //   })
      //   this.isLoggedIn = localStorage.getItem("isLogin");
      // this.nav.navigateRoot('home');
      console.log('platform', isReady);
      //   this.menuCtrl.swipeGesture(false,'001');
      // watch network for a disconnection
      this.network.onDisconnect().subscribe(
        async () => {
          console.log('network was disconnected :-(');
          let alert = await this.alertCtrl.create({
            message: 'No internet connection. Turn on internet to access app',
            buttons: ['OK'],
          });
          alert.present();
        },
        (error) => {
          console.log('failed to access network', error);
        }
      );

      // watch network for a connection
      this.network.onConnect().subscribe(() => {
        this.globals.presentToast('Connecting...');
        console.log('network connected!');
        this.nav.navigateRoot('home');
        // let response = this.server.CheckInternet()
        // response.subscribe(data => {
        //     console.log(data)
        //     console.log('network connected and has access to internet');
        //     this.gotoHome();
        //     window.location.reload()

        // }, error => {
        //     console.log('no network', error)
        //     this.globals.presentToast('Error...')
        //     // let alert = this.alertCtrl.create({
        //     //     message: 'Seems like your Network is not providing service. Try recharging',
        //     //     buttons: ['OK']
        //     // })
        //     // alert.present();

        // });
      });
      // this.backgroundMode.s
      //    setInterval(() => {
      // handle foreground and background
      if (this.platform.is('cordova')) {
        //Subscribe on pause i.e. background
        this.platform.pause.subscribe(() => {
          this.globals.appInBackground = true;

          console.log('paused platform');
          //Hello pause
        });

        //Subscribe on resume i.e. foreground
        this.platform.resume.subscribe(() => {
          this.globals.appInBackground = false;
          console.log('resumed app platform');
          window['paused'] = 0;
        });
      }
      //   let isactive=  this.backgroundMode.isActive()
      //   console.log('background mode ',isactive)
      //   this.globals.appInBackground=isactive;
      //    }, 4000);
      handleBranch();
      // this.deeplinks.route({
      // //  data:{},
      //   }).subscribe(match => {
      //     // match.$route - the route we matched, which is the matched entry from the arguments to route()
      //     // match.$args - the args passed in the link
      //     // match.$link - the full link data
      //     console.log('Successfully matched route', match);
      //   }, nomatch => {
      //     // nomatch.$link - the full link data
      //     console.error('Got a deeplink that didn\'t match', nomatch);
      //   });
      // FB.init()
      // this.oneSignalNotifi();
      // if (this.platform.is('browser') || this.platform.is('mobileweb')) {
      //     // this.oneSignal.
      //     // this.oneSignal.startInit(this.oneSignalAppId);
      //     console.log('browser testing')
      //     this.initWebPush();

      // }
      if (this.platform.is('cordova')) {
        //if platform is cordova ,for onesignal settings

        if (this.platform.is('android')) {
          //for android google sender id is required
          // this.oneSignal.startInit(this.oneSignalAppId, this.sender_id);
          console.log('android');
        }
        // else if (this.platform.is('browser') || this.platform.is('mobileweb')) {
        //     // this.oneSignal.
        //     // this.oneSignal.startInit(this.oneSignalAppId);
        //     // this.initWebPush();

        //     console.log('browser')

        // }
        else if (this.platform.is('ios')) {
          //new
          var iosSettings = {};
          iosSettings['kOSSettingsKeyAutoPrompt'] = true;
          iosSettings['kOSSettingsKeyInAppLaunchURL'] = false;
          // this.oneSignal
          //   .startInit(this.oneSignalAppId)
          //   .iOSSettings(iosSettings);
          //new
          // this.oneSignal.startInit(this.oneSignalAppId);
          console.log('ios');
        }
        // this.oneSignal.inFocusDisplaying(
        //   this.oneSignal.OSInFocusDisplayOption.Notification
        // );

        // this.oneSignal.handleNotificationReceived().subscribe((data) => {
        //   console.log('notif data', data);

        //   this.onPushReceived(data.payload);
        // });
        // this.oneSignal.handleNotificationOpened().subscribe((data) => {
        //   this.onPushOpened(data.notification.payload);
        // });
        // this.oneSignal.endInit();
        // this.oneSignal.getIds().then((identity) => {
        //   let deviceToken = identity.pushToken;
        //   let userId = identity.userId;
        //   this.globals.userId = userId;
        //   this.globals.pushToken = deviceToken;
        //   this.globals.oneSignalAppId = this.oneSignalAppId;

        //   console.log(identity.pushToken + " it's PUSHTOKEN");
        //   console.log(identity.userId + " it's USERID");
        // });
        //   this.sendNotificationData();
      }
      //end of if condition checking cordova
      //   this.oneSignal.
      // Sentry.init({
      //     dsn: "https://examplePublicKey@o0.ingest.sentry.io/0" ,
      //     integrations: [
      //       // Registers and configures the Tracing integration,
      //       // which automatically instruments your application to monitor its
      //       // performance, including custom Angular routing instrumentation
      //       new Integrations.BrowserTracing({
      //         tracingOrigins: ["localhost", "https://yourserver.io/api"],
      //         routingInstrumentation: Sentry.routingInstrumentation,

      //       }),
      //     ],

      //     // Set tracesSampleRate to 1.0 to capture 100%
      //     // of transactions for performance monitoring.
      //     // We recommend adjusting this value in production
      //     tracesSampleRate: 1.0,
      //   });

      // this.checkCodePush();
      this.data = {};
      this.data.response = '';

      this.statusbar.overlaysWebView(false);
      if (window.statusbar) {
        this.statusbar.show();
      }

      this.statusbar.backgroundColorByHexString('#ffffff');
      //   this.statusbar.styleLightContent()
      this.statusbar.styleDefault();

      this.checkAppVersion();
      // this.splashscreen.hide();
      let env = this;

      this.nativeStorage
        .getItem('user')
        .then((data) => {
          this.globals.udid = data.udid;
          this.globals.firstName = data.firstName;
          this.globals.lastName = data.lastName;
          this.globals.Email = data.email;
        })
        .catch((err) => {
          console.log(err);
        });

      this.loadBanner();
    });
    // disabled because ios issue.... needed to enable again. when needed
    this.platform.resume.subscribe(() => {
    handleBranch();
    });
    // branch io............................................
    const handleBranch = () => {
        // only on devices
        if (!this.platform.is('cordova')) { return }
        // this.branch = window['Branch'];
        this.branch.initSession().then(data => {
            if (data['+clicked_branch_link']) {
                // read deep link data on click
                console.log('Deep Link Data: ', data);
                if(data.businessPage=='true'){ //link for only open business detail page and review
                    console.log(' business page link true')
                let businessData=JSON.parse(data.business)
                console.log('parsed business data from link',businessData)
                this.globals.activeBusinessData=businessData;
                // this.nav.navigateForward(''+'/'+businessData.name+"/"+businessData.id);
                let name = businessData.name + '-' + businessData.address;
                console.log(businessData.name, businessData.address);
                name = name.replace(/[^a-zA-Z ]/g, '');
                name = name.replace(/ /g, '-');
                name = name.replace(/--/g, '-');
                // value=value.replace(/,/g,'')
                this.router.navigateByUrl('' + name + '/' + businessData.business_id, {
                  state: { businessData: businessData, uniqueid: businessData.business_id,openReviewPage:'true' },
                });
                }
            }
        });
    }
    // Branch initialization.......................................................
    // disabled because ios issue.... needed to enable again. when needed
  }

  // initializeApp(){

  // }
  // oneSignalNotifi(){
  //     // this.splashscreen.hide();
  //     // if (isCordovaAvailable()){

  //     //   }
  // }
  // sendNotificationData(){
  //     let response = this.server.getNotificationDataInit(this.globals.oneSignalAppId,this.globals.userId)
  //     console.log('sending this data to api > ',this.globals.oneSignalAppId,this.globals.userId)
  //     response.subscribe(data => {
  //         console.log('notification api response',data)

  //     })
  // }
  // private onPushReceived(payload: OSNotificationPayload) {
  //   console.log('notfication recieved', payload);
  //   // let currentTimeSpan=Date.now();
  //   console.log('current timespan is ', this.currentTimeSpan);
  //   let recievedTime = Number(payload.additionalData.time);
  //   console.log('rec time span is ', recievedTime);
  //   // this.timeDifference(this.currentTimeSpan,recievedTime)
  //   this.notificationsRecieved.push({ data: payload, time: recievedTime });
  //   console.log('notification body:', payload.body);
  //   // console.log('raw data',JSON.parse(payload.rawPayload))
  //   // let rawData=JSON.parse(payload.rawPayload)
  //   //  let recTime=rawData.
  //   //  console.log("notification time",recTime)
  //   // google.sent_time
  //   console.log('all notifications recieved', this.notificationsRecieved);
  //   this.globals.allNotifications = this.notificationsRecieved;
  // }

  // private async onPushOpened(payload: OSNotificationPayload) {
  //   console.log('notification opened: ', payload);
  //   console.log('notification body opened: ' + payload.body);
  //   // const alert = this.alertCtrl.create({
  //   //     message: payload.body,
  //   //     buttons: ['Ok']
  //   // });
  //   // await alert.present();
  // }
  // .....web oneSignal....... not needed
  //     initWebPush(){

  //   var that = this;

  //   this.OneSignal = window["OneSignal"];

  //   this.OneSignal.push(["init", {
  //     appId: this.oneSignalAppId,
  //     // autoRegister: true,
  // //     httpPermissionRequest: {
  // //       enable: true
  // //     },
  // //     welcomeNotification: {
  // //       disable: true
  // //     },
  // //     notifyButton: {
  // //       enable: false
  // //     },
  // //     promptOptions: {
  // //       siteName: 'Push Notifications',
  // //       actionMessage: "Please Allow Push Notifications.",
  // //       exampleNotificationTitle: 'Holiday Approved',
  // //       exampleNotificationMessage: 'Your holiday request was approved.',
  // //       exampleNotificationCaption: 'You can unsubscribe at anytime.',
  // //       acceptButtonText: "ALLOW",
  // //       cancelButtonText: "NO THANKS"
  // //   }
  //   }]);

  //   this.OneSignal.push( ()=> {
  //     this.OneSignal.isPushNotificationsEnabled( (isEnabled)=> {

  //       if (!isEnabled) {
  //         this.OneSignal.push(function () {
  //           this.OneSignal.registerForPushNotifications({
  //             modalPrompt: true
  //           });
  //         });
  //       }
  //       else {
  //         this.OneSignal.push(function () {
  //           this.OneSignal.getUserId().then((userId)=> {
  //               console.log('web user id',userId)
  //             that.oneSignalId = userId;
  //           });
  //         });
  //       }
  //     });
  //   });

  //   this.OneSignal.push(function () {
  //     this.OneSignal.on('subscriptionChange', function (isSubscribed) {
  //       if (isSubscribed) {
  //         this.OneSignal.getUserId().then(function (userId) {
  //             console.log('web user id',userId)
  //           that.oneSignalId = userId;
  //         });
  //       }
  //       else {
  //         that.oneSignalId = "";
  //       }
  //     });
  //   });

  //   this.OneSignal.on('notificationDisplay', (event)=> {
  //       console.log(event)
  //     // that.notificationDisplayed(event);
  //   });
  // }
  closeMenu() {
    this.menuCtrl.close();
  }
  timeDifference(current, previous) {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;
    if (current == previous) {
      return 'Just now';
    } else if (elapsed < msPerMinute) {
      console.log(Math.round(elapsed / 1000) + ' seconds ago');
      this.timeIs = Math.round(elapsed / 1000) + ' seconds ago';
      return Math.round(elapsed / 1000) + ' seconds ago';
      //  return timeIs
    } else if (elapsed < msPerHour) {
      console.log(Math.round(elapsed / msPerMinute) + ' minutes ago');
      this.timeIs = Math.round(elapsed / msPerMinute) + ' minutes ago';
      return Math.round(elapsed / msPerMinute) + ' minutes ago';
    } else if (elapsed < msPerDay) {
      console.log(Math.round(elapsed / msPerHour) + ' hours ago');
      this.timeIs = Math.round(elapsed / msPerHour) + ' hours ago';
      return Math.round(elapsed / msPerHour) + ' hours ago';
    } else if (elapsed < msPerMonth) {
      this.timeIs = Math.round(elapsed / msPerDay) + ' days ago';
      return 'approximately ' + Math.round(elapsed / msPerDay) + ' days ago';
    } else if (elapsed < msPerYear) {
      this.timeIs = Math.round(elapsed / msPerMonth) + ' months ago';
      return (
        'approximately ' + Math.round(elapsed / msPerMonth) + ' months ago'
      );
    } else {
      this.timeIs = Math.round(elapsed / msPerYear) + ' years ago';
      return 'approximately ' + Math.round(elapsed / msPerYear) + ' years ago';
    }
  }
  loadBanner() {
    let response = this.server.LoadBannersOnHomePage();
    response.subscribe(
      (data) => {
        console.log('banners', data);
        this.globals.banner_color = data.banner_color;
        this.globals.giftCard_color = data.gift_cards_color;
        this.globals.myRewards_color = data.my_rewards_color;
        this.globals.orderNow_color = data.order_now_color;
        this.globals.specialOffer_color = data.special_offer_color;
        this.globals.themeColor = data.theme_color;
        // this.globals.cash_discount = data.cash_discount;

        // this.globals.cash_discount_enabled = data.cash_discount_enabled;
        // this.globals.cash_discount_percentage = data.cash_discount_percentage;
        // this.globals.cash_discount_value = data.cash_discount_value;

        //newadded
        this.globals.BusinessDiscount = data.discount;
        this.globals.availed_discount_count = parseInt(
          data.customer_discount_availed_count
        );
        this.globals.business_discount_count = parseInt(
          data.business_discount_count
        );
        //new added
        // ll
        if (data.theme_color) {
          this.globals.appColor = data.theme_color;
        } else {
          this.globals.appColor = '#000000';
        }

        // this.globals.cash_discount = data.cash_discount;

        // this.globals.cash_discount_enabled = data.cash_discount_enabled;
        // this.globals.cash_discount_percentage = data.cash_discount_percentage;
        // this.globals.cash_discount_value = data.cash_discount_value;
      },
      async (error) => {
        console.log('no network', error);
        let alert = await this.alertCtrl.create({
          message:
            "Looks like you're not connected to Internet. turn on your mobile data or Wi-Fi.",
          buttons: ['OK'],
        });
        alert.present();
      }
    );
  }

  // doApp_verify() {
  //     let response = this.server.verifyApp_version()
  //     response.subscribe(data => {
  //         if (data.success) {

  //         } else {
  //             this.splashscreen.hide();
  //             this.nav.setRoot('ResponseApiPage', { appstore_link: data.appstore_link, playstore_link: data.playstore_link, htmlBody: data.html_body });
  //         }
  //     }, error => {
  //         console.log('no network', error)
  //         let alert = this.alertCtrl.create({
  //             message: 'Seems like your Network is not providing service. Try recharging',
  //             buttons: ['OK']
  //         })
  //         alert.present();
  //     });
  // }

  // checkCodePush() {
  //     this.codePush.sync({
  //         updateDialog: {
  //             appendReleaseDescription: true,
  //             descriptionPrefix: "\n\nChange log:\n"
  //         },
  //         installMode: InstallMode.IMMEDIATE,
  //     }).subscribe(
  //         (data) => {
  //         },
  //         (err) => {
  //         }
  //     );
  // }

  // setupBusiness() {
  //     this.globals.new_id = this.places[0].business_id;
  //     this.globals.point_check = this.places[0].points_enabled;
  //     this.globals.punch_check = this.places[0].punches_enabled;
  //     this.globals.special_offer = this.places[0].special_offer;
  //     this.globals.events_enabled = this.places[0].events_enabled;
  //     this.globals.gallery_enabled = this.places[0].gallery_enabled;
  //     this.globals.pickup = this.places[0].pickup;
  //     this.globals.latitude = this.places[0].latitude;
  //     this.globals.longitude = this.places[0].longitude;
  //     this.globals.menu_ready_msg = this.places[0].menu_ready_message;
  //     this.globals.hours_operation = this.places[0].hours_operation;
  //     this.globals.branch_enabled = this.places[0].branch_enabled;
  //     this.globals.giftcard_enabled = this.places[0].giftcard_enabled;
  //     this.globals.b_logo = this.places[0].logo;
  //     this.globals.home_logo = this.places[0].logo;

  //     this.globals.business_name = this.places[0].name;
  //     this.globals.business_phone = this.places[0].phone;
  //     this.globals.business_address = this.places[0].address;
  //     this.globals.StripId = this.places[0].stripe_id;
  //     this.globals.order_instructions = this.places[0].instructions_enabled;
  //     this.globals.pickup_timing = this.places[0].pickup_timing;
  //     this.globals.delivery_timing = this.places[0].delivery_timing;
  //     this.globals.business_username = this.places[0].username;
  //     this.globals.estimated_time = this.places[0].delivery_time;
  //     // this.globals.business_discount_count = parseInt(this.places[0].business_discount_count);
  //     this.globals.username = this.places[0].username;
  //     this.globals.bussinessId = this.places[0].business_id;
  //     this.globals.admin_stripe = this.places[0].admin_stripe_enabled;
  //     this.globals.pickupsetting = this.places[0].delivery_time;
  //     this.globals.tax = this.places[0].tax;
  //     this.globals.deliveryCharges = this.places[0].delivery_fee;
  //     this.globals.pickup_Time = this.places[0].pickup_time;
  //     this.globals.minimun_order = parseInt(this.places[0].minimum_order);
  //     // this.globals.availed_discount_count = parseInt(this.places[0].customer_discount_availed_count);
  //     this.globals.paypalId = this.places[0].paypal_id;
  //     this.globals.Timing = this.places[0].hours_operation;
  //     this.globals.specific_delivery_day = this.places[0].specific_delivery_day;
  //     this.globals.review_enabled = this.places[0].review_enabled;
  //     this.globals.delivery_day = this.places[0].delivery_day;
  //     this.globals.authorize_enabled = this.places[0].authorize_enabled;
  //     this.globals.card_enabled = this.places[0].card_enabled;
  //     this.globals.admin_stripe_enabled = this.places[0].admin_stripe_enabled;
  //     this.globals.catering_enabled = this.places[0].catering_enabled;
  //     this.globals.catering_cart_enabled = this.places[0].catering_cart_enabled;
  //     this.globals.giftcard_amount_limit = this.places[0].giftcard_limit;
  //     this.globals.business_type = this.places[0].business_type;
  //     this.globals.appointment_enabled = this.places[0].appointment_enabled;
  //     this.globals.orders_enabled = this.places[0].orders_enabled;
  //     // this.globals.BusinessDiscount = this.places[0].discount;
  //     this.globals.ccFeeDisclaimer = this.places[0].ccFeeDisclaimer;
  //     this.globals.tip_enabled = this.places[0].tip_enabled;
  //     this.globals.menu_ready = this.places[0].menu_ready;
  //     console.log("app color ......", this.places)
  //     if (this.places[0].appColor) {
  //         this.globals.appColor = this.places[0].appColor;
  //     } else {
  //         // this.globals.appColor = '#000000'
  //     }

  //     // document.documentElement.style.setProperty('--primary-color', this.globals.appColor);

  //     if (this.globals.pickup == '1') {
  //         this.globals.pickup = true;
  //     } else {
  //         this.globals.pickup = false;
  //     }

  //     if (this.places[0].delivery == '1') {
  //         this.globals.delivery = true;
  //     } else {
  //         this.globals.delivery = false;
  //     }

  //     if (this.places[0].cash_enabled == '1') {
  //         this.globals.cash_enabled = true;
  //     } else {
  //         this.globals.cash_enabled = false;
  //     }

  //     if (this.globals.pickup == '1') {
  //         this.globals.pickup = true;
  //     } else {
  //         this.globals.pickup = false;
  //     }

  //     if (this.places[0].delivery == '1') {
  //         this.globals.delivery = true;
  //     } else {
  //         this.globals.delivery = false;
  //     }
  // }

  checkAppVersion() {
    let platformCheck;
    let currentUrl = window.location.hostname;
    if (this.globals.webURL == currentUrl) {
      this.WEBPlatform = true;
      this.globals.inWeb=true;
      // console.log()
    } else {
      this.WEBPlatform = false;
    }
    if (this.WEBPlatform == true) {
      return;
    }
    if (this.platform.is('ios')) {
      platformCheck = 'ios';
    } else {
      platformCheck = 'android';
    }
    let response = this.server.appVersion(platformCheck);
    response.subscribe(
      async (res) => {
        console.log('version api res ', res);

        if (res.success != true) {
          let alert = await this.alertCtrl.create({
            header: 'Halal guide',
            mode: 'ios',
            backdropDismiss: false,
            message:
              'Your Halal guide app is outdated and  cannot use it until you update your app,  please update Halal guide app from store.',
          });
          alert.present();
        }
      },
      (err) => {
        console.log(err, 'failed to checkversion');
      }
    );
  }
  home() {
    this.nav.navigateRoot('home');
  }

  login() {
    this.nav.navigateForward('login');
    this.menuCtrl.close('001');
  }
  signup() {
    this.nav.navigateForward('signup');
    this.menuCtrl.close('001');
  }
  profile() {
    this.nav.navigateForward('settings');
    this.menuCtrl.close('001');
  }
  privacy() {
    this.nav.navigateForward('terms-privacy');
    this.menuCtrl.close('001');
  }
  about() {
    this.nav.navigateForward('about-us');
    this.menuCtrl.close('001');
  }
  blogs() {
    this.nav.navigateForward('blogs');
    this.menuCtrl.close('001');
  }
 

}
